import Vue from 'vue';
import App from './App.vue';
Vue.config.productionTip = false;

import { ElementUI } from "swift-frame";
import "swift-frame/static/ElementUI/theme-chalk/index.css";

Vue.directive('preview', {
  bind: function (el, binding, vnode) {
    const vm = vnode.context;
    // 现在可以使用vm来访问Vue实例了
    // 检查是否已经创建了预览容器
    if (!vm.previewContainer) {
      vm.previewContainer = document.createElement('div');
      vm.previewContainer.style.position = 'fixed';
      vm.previewContainer.style.top = '0';
      vm.previewContainer.style.left = '0';
      vm.previewContainer.style.width = '100%';
      vm.previewContainer.style.height = '100%';
      vm.previewContainer.style.zIndex = '9999';
      vm.previewContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      vm.previewContainer.style.display = 'none';
      vm.previewContainer.style.alignItems = 'center';
      vm.previewContainer.style.justifyContent = 'center';
      vm.previewImg = document.createElement('img');
      vm.previewImg.style.maxWidth = '90%';
      vm.previewImg.style.maxHeight = '90%';
      vm.previewImg.style.objectFit = 'contain';
      vm.previewContainer.appendChild(vm.previewImg);
      document.body.appendChild(vm.previewContainer);

      // 添加点击事件以关闭全屏预览
      vm.previewContainer.onclick = function () {
        vm.previewContainer.style.display = 'none';
      }.bind(this);
    }

    el.onclick = function () {
      vm.previewImg.src = el.src;
      vm.previewContainer.style.display = 'flex';
    }.bind(this);
  },
  unbind: function (el, binding, vnode) {
    const vm = vnode.context;
    // 清理和销毁
    if (vm.previewContainer && vm.previewContainer.parentNode) {
      vm.previewContainer.parentNode.removeChild(vm.previewContainer);
    }
    vm.previewContainer = null;
    vm.previewImg = null;
  }
});


Vue.use(ElementUI);//引入elementUI
new Vue({
  render: h => h(App),
}).$mount('#app');

