<template>
  <div
    class="short-link-card">
    <div
      flex="dir:left cross:center"
      class="flex-box">
      <img
        v-preview
        width="80"
        height="80"
        flex-box="0"
        class="code"
        :src="item.shortUrlQrcode"
        alt="" />
      <div
        class="info"
        flex-box="1"
        flex="dir:top">
        <div
          flex="dir:left cross:center"
          style="font-size: larger;color: black;font-weight: 600;">
          <span style="margin-right: 10px;">短链接: {{ item.shortUrl }}</span>
        </div>
        <div style="font-size: small;color: #aaaaaa;font-weight: 400;">
          <span>原始链接: {{ item.orgUrl }}</span>
        </div>
      </div>
      <div
        class="right"
        flex="dir:top cross:center">
        <el-button
          class="copy-btn"
          type="primary"
          @click="copyToClipboard(item.shortUrl)">
          复制短链接
        </el-button>
        <div class="eff">
          有效期: {{ item.effDay == "-1"?"永久":item.effDay+"天" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShortLinkCard",
  props:{
    item: Object,
  },
  data() { 
    return {

    };
  },
  methods:{
    copyToClipboard(txt) {
      const textarea = document.createElement("textarea");
      textarea.value = txt;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message("短链已复制");
    },
  },
};
</script>

<style lang="scss" scoped>
  .short-link-card{
    width: auto;
    padding: 20px 50px;
    .flex-box{
      height: 120px;
      .code{
        margin-right: 20px;
      }
      .info{
        justify-content: space-around;
      }
      .right{
        height: 120px;
        justify-content: flex-end;
        .copy-btn{
          width: 140px;
          height: 54px;
          font-family: MicrosoftYaHeiUI-Bold;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 14px;
          font-weight: 700;
        }
        .eff{
          margin-top: 8px;
          margin-bottom: 11px;
          font-family: MicrosoftYaHeiUI;
          font-size: 14px;
          color: #909399;
          letter-spacing: 0;
          line-height: 14px;
          font-weight: 400;
        }
      }
    }
  }
</style>
