<template>
  <div
    id="app"
    class="full">
    <div
      class="pesudo-box"
      @click="toLogin">
      <div
        class="pesudo"
        flex-box="0">
        登录
      </div>
    </div>
    <main>
      <el-form
        ref="shortForm"
        inline
        :rules="rules"
        :model="form"
        label-width="80px">
        <el-form-item
          class="org-item"
          label="链接"
          prop="orgUrl">
          <el-input
            v-model="form.orgUrl"
            class="org-input"
            placeholder="请输入 http:// 或 https:// 开头的网址"></el-input>
        </el-form-item>
        <el-form-item
          class="project"
          label="项目"
          prop="projectId">
          <el-select
            v-model="form.projectId"
            clearable
            placeholder="请选择一个项目">
            <el-option
              v-for="item in projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          style=""
          label="标签"
          class="tags"
          prop="tags">
          <el-input
            v-model="form.tags"
            placeholder="请输入标签，多个标签使用逗号隔开"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center;width: 100%;">
          <el-button
            class="form-btn"
            type="primary"
            @click="onSubmit">
            生成短链接
          </el-button>
          <el-button
            class="form-btn reset" 
            @click="resetForm">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </main>
    <footer v-if="result">
      <short-link-card :item="result"></short-link-card>
    </footer>
  </div>
</template>

<script>
import {createShortLink,getShortUrlProjectList} from "service";
import shortLinkCard from '@c/shortLinkCard.vue';
export default {
  name: 'App',
  components:{
    shortLinkCard
  },
  data() {
    var validateUrl = (rule,value,callback) =>{
      if (value === '') {
        callback(new Error('请输入跳转链接'));
      }else if(!/^(https?:\/\/)/.test(value)){
        callback(new Error('请输入 http:// 或 https:// 开头的网址'));
      }else {
        callback();
      }
    };
    return {
      form: {
        orgUrl: '',
        tags:'',
        projectId: '',
      },
      rules: {
        orgUrl: [
          // { required: true, message: '请输入跳转链接', trigger: 'blur' },
          { validator: validateUrl, trigger: 'blur' }
        ],
        projectId: [
          { required: true, message: '请选择短链项目', trigger: 'change' }
        ]
      },
      projectList:[],
      result:null
      // result:{
      //   orgUrl:"https://admin2.ihealthcoming.com/reset.css",
      //   shortUrl:"https://hhmk.vip/reset.css",
      //   effDay:"30",
      //   shortUrlQrcode:"https://fileqiniu.baymy.cn/212bc57c-7681-46d2-ade6-4b94580cf417"
      // }
    };
  },
  created(){
    getShortUrlProjectList().then(resp=>{
      this.projectList = resp.data.list;
      //默认选上第一个
      this.form.projectId = resp.data.defaultProjectId;
    });
  },
  methods:{
    toLogin(){
      window.location.href = "//shortUrl." + process.env.VUE_APP_DOMAIN +"/swiftlogin.html";
    },
    // checkUrl(){
    //   this.$refs.shortForm.validate((valid) => {
    //     if(valid){
    //       this.isOpen = true;
    //     }
    //   });
    // },
    onSubmit() {
      this.result = null;
      this.submitForm();
    },
    submitForm() {
      this.$refs.shortForm.validate((valid) => {
        if (valid) {
          let {orgUrl,projectId,tags} = this.form;
          let submitData = {
            orgUrl,
            projectId
          };
          if(tags){
            submitData.tags = tags;
          }
          createShortLink(submitData).then(resp=>{
            this.$message.success(resp.reason||"生成短链成功");
            this.result = resp.data;
          });
        } else {
          this.$message.error("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.shortForm.resetFields();
      this.result = null;
      // this.isOpen = false;
    }
  },
};
</script>
<style lang="scss">
#app{
  padding: 40px;
  background-color: #f5f7fa;
  background-image: url("assets/images/index_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 294px;
}

.pesudo-box{
  margin-right: 0;
  margin-left: auto;
  width: 80px;
  height: 40px;
  background-color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 20px 50px 0px rgba(18,98,179,0.1);
  transition: margin 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover{
    cursor: pointer;
    /*background-color: transparent;
    margin-bottom: 50px;*/
  }
}
.pesudo {
    box-shadow: unset;
}
/*
.pesudo::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.pesudo::before {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.pesudo:hover {
    transform: scale(1.75, 1.75);
}

.pesudo:hover::before {
    opacity: 0;
}

.pesudo:hover::after {
    opacity: 1;
}*/
main{
  background-color: #fff;
  box-shadow: 0px 20px 50px 0px rgba(18,98,179,0.1);
  padding: 30px;
  margin: 0 auto 40px;
  max-width: 1000px;
  min-width: 700px;
  .el-form{
    display: flex !important;
    flex-wrap: wrap;
  }
  .el-form-item{
    margin-bottom: 30px;
    flex-grow: 1;
    display: flex !important;
    flex-wrap: nowrap;
    .el-form-item__label{
      width: 60px !important;
      font-size: 16px;
    }
    .el-form-item__content{
      flex-grow: 1;
    }
  }
  .is-required,.org-item{
    .el-form-item__label{
      &::before{
        display: none;
      }
      &::after{
        content:"*"
      }
    }
  }
  .org-item{
    flex-shrink: 1;
  }
  .project{
    flex-shrink: 0;
  }
  .tags{
    width: 100%;
    flex-shrink: 0;
  }
  .el-input{
    .el-input__inner{
      font-family: MicrosoftYaHeiUI;
      font-size: 14px;
      color: #606266;
      letter-spacing: 0;
      line-height: 14px;
      font-weight: 400;
    }
  }
  .org-input{
    height: 60px;
    .el-input__inner{
      height: 60px;
      font-weight: 900;
    }
  }
  .form-btn{
    width: 140px;
    height: 54px;
    font-family: MicrosoftYaHeiUI-Bold;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 700;
  }
  .form-btn.reset{
    color: #606266;
  }
}
footer{
  min-width: 700px;
  box-shadow: 0px 20px 50px 0px rgba(18,98,179,0.1);
  background-color: #fff;
}
</style>

